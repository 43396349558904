import React from 'react';

import { useDelegateAsyncAnalytics } from '@atlassian/experimental-react-experiment-framework-2/abstract-plugins';
import type {
	ExperimentCore,
	ExperimentAnalytics,
} from '@atlassian/experimental-react-experiment-framework-2/types';

import { getAnalyticsWebClient } from '@confluence/analytics-web-client';

export { usePluginAnalyticsDefaults } from '@atlassian/experimental-react-experiment-framework-2/abstract-plugins';

export const usePluginAnalytics = <Upstream extends ExperimentCore>() =>
	function useAnalytics(pipeline: Upstream): ExperimentAnalytics {
		const awc = React.useMemo(() => getAnalyticsWebClient(), []);
		return useDelegateAsyncAnalytics<Upstream>(awc)(pipeline);
	};
