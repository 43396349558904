import type { FC } from 'react';
import React from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import AppSwitcherIcon from '@atlaskit/icon/glyph/app-switcher';
import NotificationIcon from '@atlaskit/icon/glyph/notification';
import QuestionCircleIcon from '@atlaskit/icon/glyph/question-circle';
import { IconButton } from '@atlaskit/atlassian-navigation';
import { N0 } from '@atlaskit/theme/colors';
import type { TriggerProps } from '@atlaskit/popup';

import { LoadableAfterPaint } from '@confluence/loadable';
import { InteractiveSearchInputSkeleton } from '@confluence/navigation-search-dialog';
import { GlobalCreateContentButton } from '@confluence/create-blank-fabric-page';
import { useIsCreationDropdownEnabled } from '@confluence/content-types-utils';

import { NavItemWrapper } from './presentationalComponents';
import { CreateButton } from './CreateButton';

export const LazyAppSwitcher = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-AppSwitcherComponentWrapper" */ './AppSwitcherComponentWrapper'
			)
		).AppSwitcherComponentWrapper,
	loading: () => (
		// This placeholder should match the AppSwitcher component from @atlaskit/atlassian-navigation
		<IconButton icon={<AppSwitcherIcon label="" />} tooltip="" />
	),
	_noPreloadWhenRenderingSPA: true,
}) as FC<{}>;

// This placeholder should match the Notifications component from @atlaskit/atlassian-navigation
const NotificationsPlaceholder = () => {
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
	const Wrapper = styled.div({
		position: 'relative',
	});
	return (
		<Wrapper>
			<IconButton icon={<NotificationIcon label="" />} tooltip="" />
		</Wrapper>
	);
};

export const LazyNotifications = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-NotificationsComponent" */ './NotificationsComponent'
			)
		).NotificationsComponent,
	loading: NotificationsPlaceholder,
	_noPreloadWhenRenderingSPA: true,
}) as FC<{}>;

export const LazyNotes = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-NotesComponent" */ './NotesComponent'))
			.NotesComponent,
	loading: () => null,
	_noPreloadWhenRenderingSPA: true,
}) as FC<{}>;

export const LazySettings = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-SettingsComponent" */ './SettingsComponent'))
			.SettingsComponent,
	_noPreloadWhenRenderingSPA: true,
}) as FC<{}>;

export const LazyCreate = () => {
	// There is currently no option for creating a folder in the global create menu
	const isCreationDropdownEnabled = useIsCreationDropdownEnabled({
		canShowFolder: false,
	});
	return (
		<NavItemWrapper>
			{isCreationDropdownEnabled ? <LazyGlobalCreatePopover /> : <LazyCreateComponent />}
		</NavItemWrapper>
	);
};

const LazyCreateComponent = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-CreateComponent" */ '../CreateComponent'))
			.CreateComponent,
	loading: () => <CreateButton />,
	_noPreloadWhenRenderingSPA: true,
}) as FC<{}>;

const LazyGlobalCreatePopover = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-CreateComponent" */ '../CreateComponent'))
			.CreateComponent,
	loading: () => <GlobalCreateContentButton triggerProps={{} as TriggerProps} onClick={() => {}} />,
	_noPreloadWhenRenderingSPA: true,
}) as FC<{}>;

export const LazyHelp = LoadableAfterPaint({
	loader: async () => {
		const [{ MenuComponent }, { HelpComponent }] = await Promise.all([
			import(/* webpackChunkName: "loadable-MenuComponent" */ './MenuComponent'),
			import(/* webpackChunkName: "loadable-HelpComponent" */ './HelpComponent'),
		]);
		return (props) => <MenuComponent menu={HelpComponent} {...props} />;
	},
	loading: () =>
		// This placeholder should match the Help component from @atlaskit/atlassian-navigation
		React.createElement(
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
			styled.div({
				marginRight: token('space.050', '4px'),
			}),
			{},
			React.createElement(() => (
				<IconButton
					icon={<QuestionCircleIcon label="" secondaryColor={token('color.icon.inverse', N0)} />}
					tooltip=""
				/>
			)),
		),
	_noPreloadWhenRenderingSPA: true,
}) as FC<{}>;

export const LazySearch = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-SearchComponent" */ './SearchComponent'))
			.SearchComponent,
	loading: ({ theme }) => <InteractiveSearchInputSkeleton theme={theme} />,
	_noPreloadWhenRenderingSPA: true,
});

export const LazyProfile = LoadableAfterPaint({
	loader: async () => {
		const [{ MenuComponent }, { ProfileComponent }] = await Promise.all([
			import(/* webpackChunkName: "loadable-MenuComponent" */ './MenuComponent'),
			import(/* webpackChunkName: "loadable-ProfileComponent" */ './ProfileComponent'),
		]);
		return (props) => <MenuComponent menu={ProfileComponent} {...props} />;
	},
	loading: () =>
		React.createElement(
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
			styled.div({
				/* 36px actual size + 4px Right margin */
				width: '36px',
				height: '36px',
			}),
		),
}) as FC<{}>;
