import { ActivityEventType, ActivitiesObjectType } from '../__types__/RecentlyWorkedOnQuery';

export const PAGE_SIZE = 25;

export const WORKED_ON_EVENT_TYPES = [
	ActivityEventType.COMMENTED,
	ActivityEventType.EDITED,
	ActivityEventType.PUBLISHED,
];

export const OBJECT_TYPES = [ActivitiesObjectType.PAGE, ActivitiesObjectType.BLOGPOST];
