import { useIntl } from 'react-intl-next';

import { useSessionData } from '@confluence/session-data';

import { isLocaleEnglish } from '../utils/isLocaleEnglish';
import { isExperimentEnabledForLocale } from '../utils/isExperimentFlagEnabled';

type UseIsExperimentFlagEnabledHook = (args: {
	/**
	 * Feature flag name.
	 */
	flagName: string;
	/**
	 * Indicates whether the locale is restricted in the experiment:
	 * - if `undefined`, experiment is not restricted to any locale
	 * - if `english`, experiment is restricted to English locale only
	 * - if `non-english`, experiment is restricted to non-English locales.
	 */
	localeRestriction?: 'english' | 'non-english';
	/**
	 * Indicates whether to fire the exposure event:
	 *  - if `false`, evaluating the feature flag won't fire the exposure event.
	 *  - if `true`, evaluating the feature flag will fire the exposure event only if user is in the correct locale(s).
	 */
	shouldTrackExposureEvent?: boolean;
	skip?: boolean;
	isStatSig?: boolean;
}) => boolean;

/**
 * React hook to evaluate experiment variant feature flag and track exposure events for users.
 *
 * @returns {boolean} `true` if the feature flag is set to ENROLLED or EXPERIMENT and users in the correct locale(s)
 */
export const useIsExperimentFlagEnabled: UseIsExperimentFlagEnabledHook = ({
	flagName,
	localeRestriction,
	shouldTrackExposureEvent = true,
	skip = false,
	isStatSig = false,
}) => {
	const { locale } = useIntl();
	const { featureFlagClient } = useSessionData();
	if (skip) return false;

	let isUserLocaleCorrect;
	switch (localeRestriction) {
		case 'english':
			isUserLocaleCorrect = isLocaleEnglish(locale);
			break;
		case 'non-english':
			isUserLocaleCorrect = !isLocaleEnglish(locale);
			break;
		default:
			isUserLocaleCorrect = true;
			break;
	}

	return isExperimentEnabledForLocale({
		featureFlagClient,
		flagName,
		isUserLocaleCorrect,
		shouldTrackExposureEvent,
		isStatSig,
	});
};
