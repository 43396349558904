import type { FC } from 'react';
import React from 'react';

import { LoadableLazy } from '@confluence/loadable';

import { useCompanyHubBuilderStore } from './CompanyHubBuilderStore';

const CompanyHubBuilderDrawerLoader = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-CompanyHubBuilderDrawer" */ './CompanyHubBuilderDrawer'
			)
		).CompanyHubBuilderDrawer,
});

export const ConditionalCompanyHubBuilderDrawer: FC = () => {
	const [shouldRenderDrawer] = useCompanyHubBuilderStore();
	return shouldRenderDrawer ? <CompanyHubBuilderDrawerLoader /> : null;
};
