import React from 'react';

import { Attribution, TransparentErrorBoundary } from '@confluence/error-boundary';
import { LoadableLazy } from '@confluence/loadable';

import { isTrialStandardDifferentiatorsEnrolled } from '@confluence/onboarding-helpers/entry-points/flags/experiment-edition-awareness-flags';

const StandardDifferentiatorsHelpLoadable = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-StandardDifferentiatorsHelp" */ '../src/StandardDifferentiatorsHelp'
			)
		).StandardDifferentiatorsHelp,
});

export interface StandardDifferentiatorsHelpProps {
	onClose: () => void;
}

export const StandardDifferentiatorsHelp = ({ onClose }: StandardDifferentiatorsHelpProps) => {
	return isTrialStandardDifferentiatorsEnrolled() ? (
		<TransparentErrorBoundary attribution={Attribution.GROWTH_LUNA}>
			<StandardDifferentiatorsHelpLoadable onClose={onClose} />
		</TransparentErrorBoundary>
	) : null;
};
