import { type ExperimentFeatureFlag } from '../abstract/featureFlag';
import { type ExperimentCore, type ExperimentError, type ExperimentErrorType } from '../core/types';
import { mark } from './mark';

export const markError = <Upstream extends ExperimentCore & Partial<ExperimentError>>(
	error: Error | ExperimentErrorType | null | undefined,
	pipeline: Upstream,
): Upstream & ExperimentError =>
	mark(
		{
			error: pipeline.error || error ? toExperimentError(error!, pipeline) : null,
		},
		pipeline,
	);

export function toExperimentError(
	error: Error | ExperimentErrorType,
	pipeline: ExperimentCore & Partial<ExperimentFeatureFlag<string | boolean>>,
): ExperimentErrorType {
	if (isExperimentError(error)) {
		return error as ExperimentErrorType;
	}
	const currentPlugin = (pipeline && pipeline.meta && pipeline.meta.currentPlugin) || { index: 0 };
	return {
		rawError: error as Error,
		pluginIndex: currentPlugin.index,
		safeMessage:
			((error && (error as Error).name) || 'UnknownError') + ` at plugin ${currentPlugin.index}`,
		handled: false,
		flagKey: pipeline.featureFlag?.name,
	};
}

export function isExperimentError(error: Error | ExperimentErrorType) {
	return error && 'rawError' in error;
}
