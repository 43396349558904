import type { FC } from 'react';
import React, { memo, useMemo } from 'react';
import memoize from 'memoize-one';

import CustomItem from '@atlaskit/menu/custom-item';
import AkAddonIcon from '@atlaskit/icon/glyph/addon';

import { GLOBAL_PAGE_FORGE } from '@confluence/named-routes';
import type { ForgeUIGlobalPageExtensionType } from '@confluence/forge-ui';
import { getAppId, extensionTitle } from '@confluence/forge-ui';

import { UnstyledPopupLink } from '../../presentationalComponents';

import { StyledIcon } from './StyledIcon';

const getIconWebItem = memoize((app: ForgeUIGlobalPageExtensionType) => {
	const { properties } = app;
	const { icon } = properties;
	if (icon) {
		return <StyledIcon url={icon} />;
	}
	return <AkAddonIcon label="" />;
});

export const getForgeAppURL = memoize((app: ForgeUIGlobalPageExtensionType): string => {
	const { environmentId, properties } = app;
	return GLOBAL_PAGE_FORGE.toUrl({
		appId: getAppId(app),
		envId: environmentId,
		forgeManifestRoute: properties.route,
	});
});

type AppForgeItemProps = {
	app: ForgeUIGlobalPageExtensionType;
};

export const AppForgeItem: FC<AppForgeItemProps> = memo(({ app }) => {
	const icon = getIconWebItem(app);
	const customComponent = useMemo(
		() => (props) => {
			const { wrapperClass = '', className = '' } = props;
			return (
				<UnstyledPopupLink
					{...props}
					to={getForgeAppURL(app)}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={`${wrapperClass} ${className}`}
				>
					{props.children}
				</UnstyledPopupLink>
			);
		},
		[app],
	);

	return (
		<CustomItem component={customComponent} iconBefore={icon}>
			{extensionTitle(app)}
		</CustomItem>
	);
});
