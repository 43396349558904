import type FeatureFlagClient from '@atlaskit/feature-flag-client';
import type { CustomAttributes } from '@atlaskit/feature-flag-client/types';
import FeatureGates from '@atlaskit/feature-gate-js-client';

import { Cohorts } from '../constants/cohorts';

import { isLocaleEnglish } from './isLocaleEnglish';

/**
 * Helper function to evaluate experiment variant feature flag and track exposure events for users in the correct locale(s)
 * @param featureFlagClient {FeatureFlagClient} The instance of FeatureFlagClient https://atlaskit.atlassian.com/packages/growth/feature-flag-client
 * @param flagName {string} Feature flag name
 * @param isUserLocaleCorrect {boolean} Optional, determine if locale is correct for the experiment
 * @param shouldTrackExposureEvent {boolean} Optional, default to `true`.
 * Flag to decide on firing the exposure event or not.
 *  - if `false`, evaluating the feature flag won't fire the exposure event.
 *  - if `true`, evaluating the feature flag will fire the exposure event only if user is also in English locales.
 * @param {boolean} isStatSig Optional Param to use StatSig instead of Launch Darkly to evaluate the flagName
 * @returns {boolean} Returns `true` if the FF is set to ENROLLED or is EXPERIMENT and users in English locales only
 */
export function isExperimentEnabledForLocale({
	featureFlagClient,
	flagName,
	isUserLocaleCorrect,
	shouldTrackExposureEvent = true,
	isStatSig = false,
	exposureData,
}: {
	featureFlagClient: FeatureFlagClient;
	flagName: string;
	isUserLocaleCorrect?: boolean;
	shouldTrackExposureEvent?: boolean;
	isStatSig?: boolean;
	exposureData?: CustomAttributes;
}): boolean {
	const defaultValue = Cohorts.NOT_ENROLLED;
	const trackExposureEvent = isUserLocaleCorrect && shouldTrackExposureEvent;
	const featureValue = isStatSig
		? getStatsigCohort(flagName, defaultValue, trackExposureEvent)
		: featureFlagClient.getVariantValue(flagName, {
				default: defaultValue,
				oneOf: Object.values(Cohorts),
				shouldTrackExposureEvent: trackExposureEvent,
				exposureData,
			});

	if (featureValue === Cohorts.EXPERIMENT && isUserLocaleCorrect) {
		return isUserLocaleCorrect;
	}

	return featureValue === Cohorts.ENROLLED;
}

function getStatsigCohort(
	flagName: string,
	defaultValue: Cohorts,
	fireExperimentExposure?: boolean,
) {
	try {
		return FeatureGates.getExperimentValue(flagName, 'cohort', defaultValue, {
			fireExperimentExposure,
		});
	} catch (e) {
		return defaultValue;
	}
}

/**
 * A helper function to evaluate experiment feature flag and track exposure events for users in English locale only.
 * @param featureFlagClient {FeatureFlagClient} The instance of FeatureFlagClient https://atlaskit.atlassian.com/packages/growth/feature-flag-client
 * @param flagName {string} Feature flag name
 * @param locale {string} Locale such as "en" or "en-US"
 * @param shouldTrackExposureEvent {boolean} Optional, default to `true`.
 * Flag to decide on firing the exposure event or not.
 *  - if `false`, evaluating the feature flag won't fire the exposure event.
 *  - if `true`, evaluating the feature flag will fire the exposure event only if user is also in English locales.
 * @returns {boolean} Returns `true` if the FF is set to ENROLLED or is EXPERIMENT and users in English locales only
 */
export const isExperimentFlagEnabledForEnglishLocale = (
	featureFlagClient: FeatureFlagClient,
	flagName: string,
	locale: string,
	shouldTrackExposureEvent: boolean = true,
	exposureData?: CustomAttributes,
): boolean =>
	isExperimentEnabledForLocale({
		featureFlagClient,
		flagName,
		isUserLocaleCorrect: isLocaleEnglish(locale),
		shouldTrackExposureEvent,
		exposureData,
	});

/**
 * A helper function to evaluate experiment feature flag and track exposure events for users in non-English locales only.
 * @param featureFlagClient {FeatureFlagClient} The instance of FeatureFlagClient https://atlaskit.atlassian.com/packages/growth/feature-flag-client
 * @param flagName {string} Feature flag name
 * @param locale {string} Locale such as "en" or "en-US"
 * @param shouldTrackExposureEvent {boolean} Optional, default to `true`.
 * Flag to decide on firing the exposure event or not.
 *  - if `false`, evaluating the feature flag won't fire the exposure event.
 *  - if `true`, evaluating the feature flag will fire the exposure event only if user is also in non-English locales.
 * @returns {boolean} Returns `true` if the FF is set to ENROLLED or is EXPERIMENT and users in non-English locales only
 */
export const isExperimentFlagEnabledForNonEnglishLocales = (
	featureFlagClient: FeatureFlagClient,
	flagName: string,
	locale: string,
	shouldTrackExposureEvent: boolean = true,
	exposureData?: CustomAttributes,
): boolean =>
	isExperimentEnabledForLocale({
		featureFlagClient,
		flagName,
		isUserLocaleCorrect: !isLocaleEnglish(locale),
		shouldTrackExposureEvent,
		exposureData,
	});

/**
 * A helper function to evaluate experiment feature flag and track exposure events for users in any locale
 * @param featureFlagClient {FeatureFlagClient} The instance of FeatureFlagClient https://atlaskit.atlassian.com/packages/growth/feature-flag-client
 * @param flagName {string} Feature flag name
 * @param shouldTrackExposureEvent {boolean} Optional, default to `true`.
 * Flag to decide on firing the exposure event or not.
 *  - if `false`, evaluating the feature flag won't fire the exposure event.
 *  - if `true`, evaluating the feature flag will fire the exposure event
 * @returns {boolean} Returns `true` if the FF is set to ENROLLED or is EXPERIMENT
 */
export const isExperimentFlagEnabled = (
	featureFlagClient: FeatureFlagClient,
	flagName: string,
	shouldTrackExposureEvent: boolean = true,
	exposureData?: CustomAttributes,
): boolean =>
	isExperimentEnabledForLocale({
		featureFlagClient,
		flagName,
		isUserLocaleCorrect: true,
		shouldTrackExposureEvent,
		exposureData,
	});
