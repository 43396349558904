import React from 'react';
import { styled } from '@compiled/react';

const checkForSvg = (url: string) => {
	const fileType = url.split(/\#|\?/)[0].split('.').pop();
	return !!fileType && fileType.trim() === 'svg';
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.span({
	height: '24px',
	width: '24px',
});

type IconCustomProps = {
	url: string;
};

const colorMatrixValues = '0.02, 0, 0, 0, 0, 0, 0.04, 0, 0, 0, 0, 0, 0.07, 0, 0, 0, 0, 0, 1, 0';

export const StyledIcon = ({ url }: IconCustomProps) => (
	<Wrapper>
		<svg viewBox="0 0 24 24">
			<defs>
				<filter id="changeFillColor">
					<feColorMatrix type="matrix" values={colorMatrixValues} />
				</filter>
			</defs>
			{/* eslint-disable-next-line no-restricted-syntax */}
			<image
				data-testid="forgeIcon"
				xmlnsXlink="http://www.w3.org/1999/xlink"
				xlinkHref={url}
				width="24"
				height="24"
				x="0"
				y="0"
				filter={checkForSvg(url) ? 'url(#changeFillColor)' : ''}
			/>
		</svg>
	</Wrapper>
);
