import React, { memo } from 'react';
import type { FC } from 'react';

import { StandardDifferentiatorsHelp } from '@confluence/experiment-standard-differentiators/entry-points/StandardDifferentiatorsHelp';
import {
	useInProductHelpActions,
	useShouldShowStandardDifferentiators,
} from '@confluence/in-product-help';

interface StandardDifferentiatorsProps {}

export const StandardDifferentiators: FC<StandardDifferentiatorsProps> = memo(() => {
	const inProductHelpContext = useInProductHelpActions();
	const shouldShowStandardDifferentiators = useShouldShowStandardDifferentiators();
	const handleStandardDifferentiatorsHelpClose = () => {
		inProductHelpContext.setShouldShowStandardDifferentiators(false);
	};
	return shouldShowStandardDifferentiators ? (
		<StandardDifferentiatorsHelp onClose={handleStandardDifferentiatorsHelpClose} />
	) : null;
});
