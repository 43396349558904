import {
	useDelegateSwitcherooBooleanFeatureFlag,
	DefaultCohortMapSwitcheroo,
} from '@atlassian/experimental-react-experiment-framework-2/abstract-plugins';
import type {
	ExperimentCore,
	ExperimentResolution,
	ExperimentFeatureFlag,
	CohortMapSwitcheroo,
} from '@atlassian/experimental-react-experiment-framework-2/types';

import { useSessionData } from '@confluence/session-data';

type RequiredUpstream = ExperimentCore;

type Options = {
	shouldTrackExposureEvent?: boolean;
	cohortMap?: CohortMapSwitcheroo;
	otherFeatureFlagAttributes?: Record<string, any>;
};

export const usePluginSwitcherooBooleanFeatureFlag = <Upstream extends RequiredUpstream>(
	flagName: string,
	defaultValue: boolean,
	{ shouldTrackExposureEvent = false, cohortMap, otherFeatureFlagAttributes }: Options = {},
) =>
	function useSwitcherooBooleanFeatureFlagOnPipeline(
		pipeline: Upstream,
	): Upstream & ExperimentFeatureFlag<boolean> & ExperimentResolution {
		const { featureFlagClient } = useSessionData();
		const { value: flagValue, explanation: flagExplanation } = featureFlagClient.getFlagEvaluation(
			flagName,
			{
				default: defaultValue,
				shouldTrackExposureEvent,
			},
		);

		return useDelegateSwitcherooBooleanFeatureFlag<Upstream>({
			flagName,
			flagValue: flagValue ?? defaultValue,
			flagExplanation,
			cohortMap: cohortMap ?? DefaultCohortMapSwitcheroo,
			otherFeatureFlagAttributes: {
				...otherFeatureFlagAttributes,
				reason: flagExplanation?.kind,
				ruleId: flagExplanation?.ruleId,
			},
		})(pipeline);
	};
