import type { FC } from 'react';
import React, { memo, useContext, useCallback } from 'react';
import { defineMessages } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next/useAnalyticsEvents';

import { SSRMouseEventWrapper, SSR_NAV_HOME_BUTTON_METRIC } from '@confluence/browser-metrics';
import { useRouteDataRef, useRouteName } from '@confluence/route-manager';
import { WIKI, HOME } from '@confluence/named-routes';
import { AppNavigationContext } from '@confluence/app-navigation-context';
import { createLazyCallbackHook } from '@confluence/loadable/entry-points/lazy-callback';
import { GO_HOME_SHORTCUT } from '@confluence/shortcuts';

import type { PrimaryItemProps } from './PrimaryItem';
import { PrimaryItem } from './PrimaryItem';

const i18n = defineMessages({
	HomeLink: {
		id: 'app-navigation.home.link',
		description: 'link to the Home page',
		defaultMessage: 'Home',
	},
});

const useLazyClickAnalytics = createLazyCallbackHook(
	async () =>
		(await import(/* webpackChunkName: "loadable-analyticsCallbacks" */ './analyticsCallbacks'))
			.fireHomeClickedAnalytics,
);

export const HomeItem: FC<PrimaryItemProps> = memo(({ testId, pendoId }) => {
	const routeDataRef = useRouteDataRef();
	const { resetStickySearchRef } = useContext(AppNavigationContext);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const fireClickAnalytics = useLazyClickAnalytics(createAnalyticsEvent, routeDataRef);
	const handleClick = useCallback(() => {
		resetStickySearchRef?.current?.();
		void fireClickAnalytics();
	}, [fireClickAnalytics, resetStickySearchRef]);

	const isOnHomeOrWikiRouteArgs = {
		selector: (routeName: string | undefined) => {
			if (!routeName) {
				return false;
			}
			return routeName === WIKI.name || routeName === HOME.name;
		},
	};
	const isHighlighted = useRouteName(isOnHomeOrWikiRouteArgs);

	return (
		<SSRMouseEventWrapper metricName={SSR_NAV_HOME_BUTTON_METRIC}>
			<PrimaryItem
				testId={testId}
				i18n={i18n.HomeLink}
				namedRoute={HOME}
				keyboardShortcut={GO_HOME_SHORTCUT}
				isAppNavigationShortcut
				onClick={handleClick}
				isHighlighted={isHighlighted}
				pendoId={pendoId}
			/>
		</SSRMouseEventWrapper>
	);
});
