import { type ExperimentCore } from '../types';
import { VARIATION, NOT_ENROLLED, CONTROL } from '../helpers';
import { type ExperimentResolution } from '../portable/resolver';
import type { FlagExplanation } from '@atlaskit/feature-flag-client/types';

type AttributesType = Record<string, any>;

export type CohortMap = Parameters<typeof useDelegateBooleanFeatureFlag>[0]['cohortMap'];

export const DefaultCohortMap: CohortMap = {
	trueCohort: VARIATION,
	falseCohort: NOT_ENROLLED,
};

export type CohortMapSwitcheroo = Parameters<
	typeof useDelegateSwitcherooBooleanFeatureFlag
>[0]['cohortMap'];

export const DefaultCohortMapSwitcheroo: CohortMapSwitcheroo = {
	trueCohort: VARIATION,
	ruleMatchCohort: CONTROL,
	fallthroughCohort: NOT_ENROLLED,
};

export interface ExperimentFeatureFlag<
	FlagValueType extends string | boolean,
	Attributes extends AttributesType = AttributesType,
> {
	featureFlag: {
		value: FlagValueType;
		name: string;
	} & AttributesType;
}

// Describes how the implemented usePluginBooleanFeatureFlag plugin is recommended to look like
export interface BooleanFeatureFlagPlugin {
	<Upstream extends ExperimentCore, Attributes extends AttributesType = AttributesType>(
		flagName: string,
		defaultValue: boolean,
		cohortMap?: CohortMap, // default to DefaultCohortMap
	): (
		pipeline: Upstream,
	) => Upstream & ExperimentFeatureFlag<boolean, Attributes> & ExperimentResolution;
}

export interface SwitcherooBooleanFeatureFlagPlugin {
	<Upstream extends ExperimentCore, Attributes extends AttributesType = AttributesType>(
		flagName: string,
		defaultValue: boolean,
		options?: {
			shouldTrackExposureEvent?: boolean;
			cohortMap?: CohortMapSwitcheroo; // default to DefaultCohortMapSwitcheroo
			otherFeatureFlagAttributes?: Attributes;
		},
	): (
		pipeline: Upstream,
	) => Upstream & ExperimentFeatureFlag<boolean, Attributes> & ExperimentResolution;
}

// Describes how the implemented usePluginMultivariateFeatureFlag plugin is recommended to look like
export interface MultivariationFeatureFlagPlugin {
	<
		Upstream extends ExperimentCore,
		FlagValueType extends string,
		Attributes extends AttributesType = AttributesType,
	>(
		flagName: string,
		variants: FlagValueType[],
		defaultValue: FlagValueType,
	): (
		pipeline: Upstream,
	) => Upstream & ExperimentFeatureFlag<string, Attributes> & ExperimentResolution;
}

const createDelegate =
	<
		Input,
		FlagValueType extends string | boolean,
		Attributes extends AttributesType = AttributesType,
	>({
		flagName,
		flagValue,
		cohort,
		otherFeatureFlagAttributes,
	}: {
		flagName: string;
		flagValue: FlagValueType;
		cohort: string;
		otherFeatureFlagAttributes?: Attributes;
	}) =>
	(
		pipeline: Input,
	): Input & ExperimentFeatureFlag<FlagValueType, Attributes> & ExperimentResolution => ({
		...pipeline,
		cohort,
		ineligibilityReasons: [],
		featureFlag: {
			value: flagValue,
			name: flagName,
			...(otherFeatureFlagAttributes as Attributes),
		},
	});

export const useDelegateBooleanFeatureFlag = <
	Input,
	Attributes extends AttributesType = AttributesType,
>({
	flagName,
	flagValue,
	cohortMap,
	otherFeatureFlagAttributes,
}: {
	flagName: string;
	flagValue: boolean;
	cohortMap: { trueCohort: string; falseCohort: string };
	otherFeatureFlagAttributes?: Attributes;
}) =>
	createDelegate<Input, boolean, Attributes>({
		flagName,
		flagValue,
		cohort: flagValue ? cohortMap.trueCohort : cohortMap.falseCohort,
		otherFeatureFlagAttributes,
	});

export const useDelegateSwitcherooBooleanFeatureFlag = <
	Input,
	Attributes extends AttributesType = AttributesType,
>({
	flagName,
	flagValue,
	flagExplanation,
	cohortMap,
	otherFeatureFlagAttributes,
}: {
	flagName: string;
	flagValue: boolean;
	flagExplanation: FlagExplanation | undefined;
	cohortMap: {
		trueCohort: string;
		fallthroughCohort: string;
		ruleMatchCohort: string;
	};
	otherFeatureFlagAttributes?: Attributes;
}) => {
	return createDelegate<Input, boolean, Attributes>({
		flagName,
		flagValue,
		cohort: flagValue
			? cohortMap.trueCohort
			: flagExplanation?.kind === 'RULE_MATCH'
				? cohortMap.ruleMatchCohort
				: cohortMap.fallthroughCohort,
		otherFeatureFlagAttributes: {
			...(otherFeatureFlagAttributes as Attributes),
			isSwitcheroo: true,
			tags: ['measurement'],
		},
	});
};

export const useDelegateMultivariateFeatureFlag = <
	Input,
	FlagValueType extends string,
	Attributes extends AttributesType = AttributesType,
>({
	flagName,
	flagValue,
	otherFeatureFlagAttributes,
}: {
	flagName: string;
	flagValue: FlagValueType;
	otherFeatureFlagAttributes?: Attributes;
}) =>
	createDelegate<Input, FlagValueType, Attributes>({
		flagName,
		flagValue,
		cohort: flagValue,
		otherFeatureFlagAttributes,
	});
