import { useMemo } from 'react';

import type { PeopleMenuProps } from '@atlassian/people-menu';

import {
	VARIATION,
	useExperiment,
	usePluginAnalytics,
	usePluginAnalyticsDefaults,
	usePluginSwitcherooBooleanFeatureFlag,
} from '@confluence/experiments';

import { TEAM_PRODUCT_ACCESS_EXPERIMENT } from '../featureFlagKeys';

export type ProjectTeamAccessData = NonNullable<
	PeopleMenuProps['teamCreateDialogProps']
>['projectTeamAccess'];

export const useTPAExperiment = (): ProjectTeamAccessData => {
	const { analytics, cohort, featureFlag } = useExperiment(
		usePluginAnalyticsDefaults(() => ({
			source: 'team-create-dialog',
		})),
		usePluginAnalytics(),
		usePluginSwitcherooBooleanFeatureFlag(TEAM_PRODUCT_ACCESS_EXPERIMENT, false),
	);

	return useMemo(() => {
		return {
			enabled: cohort === VARIATION,
			reason: featureFlag.reason,
			growthHoldoutCohort: VARIATION,
			fireFeatureExposed: () => {
				analytics.sendTrackEvent({
					action: 'exposed',
					actionSubject: 'feature',
					attributes: {
						flagKey: featureFlag.name,
						cohort,
						value: featureFlag.value,
						reason: featureFlag.reason,
					},
					tags: ['people-teams'],
				});
			},
		};
	}, [analytics, cohort, featureFlag]);
};
