import React from 'react';

import FeatureGates from '@atlaskit/feature-gate-js-client';

import { Attribution, TransparentErrorBoundary } from '@confluence/error-boundary';
import { LoadableLazy } from '@confluence/loadable';

const RovoVideoModalLoadable = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-RovoVideoModal" */ '../src/index')).RovoVideoModal,
});

const isRovoFeatureGateEnrolled = () => {
	const isFlagEnabled = FeatureGates.checkGate('confluence_rovo_video_player_gate');
	return isFlagEnabled;
};

export const RovoVideoModal = () => {
	return isRovoFeatureGateEnrolled() ? (
		<TransparentErrorBoundary attribution={Attribution.GROWTH_LUNA}>
			<RovoVideoModalLoadable />
		</TransparentErrorBoundary>
	) : null;
};
