import type { FlagsStateContainer, ShowFlagArgs } from './FlagsStateContainer';

export type FlagAppearanceOption = {
	appearance: 'success' | 'normal' | 'warning' | 'error';
};

export const handleShowingFlag =
	(flags: FlagsStateContainer) => (flagInfo: ShowFlagArgs & FlagAppearanceOption) => {
		const { appearance } = flagInfo;
		if (appearance === 'success' || appearance === 'normal') {
			void flags.showSuccessFlag(flagInfo);
		}

		if (appearance === 'warning') {
			void flags.showWarningFlag(flagInfo);
		}

		if (appearance === 'error') {
			void flags.showErrorFlag(flagInfo);
		}
	};
