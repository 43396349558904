import React from 'react';
import { type ExperimentCore, type ExperimentError, type ExperimentErrorType } from '../core/types';
import { type ExperimentFeatureFlag } from './featureFlag';
import { markPipelineEndListener } from '../helpers/markPipelineEndListener';
import { type ExperimentErrorHandler } from '../portable/errorHandler';
import { type ExperimentResolution } from '../portable/resolver';
import { markError } from '../helpers/markError';
import { isNotEnrolled } from '../helpers/markNotEnrolled';

export interface AnalyticsEvent {
	action: string;
	actionSubject: string;
	actionSubjectId?: string;
	attributes?: Record<string, any>;
	source?: string;
	tags?: string[];
}

export interface AnalyticsScreenEvent {
	name: string;
	attributes?: Record<string, any>;
	source?: string;
}

export type AnalyticsEventType = AnalyticsEvent | AnalyticsScreenEvent;

export type AnalyticsImplementation = {
	sendScreenEvent: (event: AnalyticsScreenEvent) => void;
	sendUIEvent: (event: AnalyticsEvent) => void;
	sendTrackEvent: (event: AnalyticsEvent) => void;
	sendOperationalEvent: (event: AnalyticsEvent) => void;
};

export type AnalyticsMethodName = keyof AnalyticsImplementation;

export type ExposureEventRequiredUpstream = ExperimentCore &
	ExperimentAnalytics &
	ExperimentResolution &
	ExperimentFeatureFlag<string | boolean>;

export type EventPayloadFunc<EventPayload, Upstream> = (pipeline: Upstream) => EventPayload;

export interface ExposureEventOptions<
	EventPayload extends Partial<AnalyticsEventType>,
	Upstream extends ExposureEventRequiredUpstream,
> {
	eventType?: 'operational' | 'track';
	excludeNotEnrolled?: boolean;
	payload?: EventPayload | EventPayloadFunc<EventPayload, Upstream>;
}

export interface ExperimentAnalytics {
	analytics: AnalyticsImplementation;
	fireExperimentError: (error: Error | ExperimentErrorType) => void;
	fireExposureEvent: <
		EventPayload extends Partial<AnalyticsEventType>,
		Upstream extends ExposureEventRequiredUpstream,
	>(
		options?: ExposureEventOptions<EventPayload, Upstream>,
	) => void;
}

export const useDelegateAnalytics = <
	Upstream extends ExperimentCore & Partial<ExperimentGetAnalyticsDefaults<Upstream>>,
>(
	analyticsImplementation: AnalyticsImplementation,
) =>
	function useAnalytics(pipeline: Upstream): ExperimentAnalytics & ExperimentErrorHandler {
		const implementation = analyticsImplementation;
		const memoizedDelegate = React.useMemo(
			() => createAnalyticsDelegate<Upstream>(implementation),
			[implementation],
		);
		memoizedDelegate.startRecording();

		return {
			...markPipelineEndListener(memoizedDelegate.startSending, pipeline),
			analytics: memoizedDelegate.analytics,
			fireExperimentError: memoizedDelegate.fireExperimentError,
			fireExposureEvent: memoizedDelegate.fireExposureEvent,
			errorHandler: useHandlerErrorAnalytics<any>(),
		};
	};

const createAnalyticsDelegate = <
	Upstream extends ExperimentCore & Partial<ExperimentGetAnalyticsDefaults<Upstream>>,
>(
	implementation: AnalyticsImplementation,
) => {
	const recordedEvents: Array<{
		method: AnalyticsMethodName;
		event: AnalyticsEventType;
	}> = [];
	let recording = true;
	let lastPipeline: Upstream | null = null;

	const sendOrRecordEvent = (method: AnalyticsMethodName, event: AnalyticsEventType) => {
		if (recording) {
			recordedEvents.push({ method, event });
		} else {
			const defaults =
				lastPipeline && lastPipeline.getAnalyticsDefaults
					? lastPipeline.getAnalyticsDefaults!(lastPipeline, method)
					: {};
			const mergedAttributes =
				defaults.attributes || event.attributes
					? {
							attributes: {
								...defaults.attributes,
								...event.attributes,
							},
						}
					: {};
			implementation[method]({
				...defaults,
				...event,
				...mergedAttributes,
			} as any);
		}
	};

	const analytics: ExperimentAnalytics['analytics'] = {
		sendScreenEvent: (event: AnalyticsScreenEvent) => sendOrRecordEvent('sendScreenEvent', event),

		sendUIEvent: (event: AnalyticsEvent) => sendOrRecordEvent('sendUIEvent', event),

		sendTrackEvent: (event: AnalyticsEvent) => sendOrRecordEvent('sendTrackEvent', event),

		sendOperationalEvent: (event: AnalyticsEvent) =>
			sendOrRecordEvent('sendOperationalEvent', event),
	};

	const fireExperimentError = (error: Error | ExperimentErrorType) => {
		let attributes =
			'rawError' in error
				? toExperimentErrorAttributes(error as ExperimentErrorType)
				: { name: (error as Error).name };
		analytics.sendOperationalEvent({
			actionSubject: 'experiment',
			action: 'error',
			attributes,
		});
	};

	function fireExposureEvent<
		EventPayload extends Partial<AnalyticsEvent>,
		ExposureEventUpstream extends ExposureEventRequiredUpstream,
	>(
		this: ExposureEventUpstream,
		options?: ExposureEventOptions<EventPayload, ExposureEventUpstream>,
	) {
		// we're using this to capture the pipeline calling this function
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const currentPipeline = this;
		const { featureFlag, cohort, ineligibilityReasons } = currentPipeline;

		if (featureFlag === undefined) {
			throw new Error('No feature flag data found. Please use a feature flag plugin.');
		}

		const {
			name: flagKey,
			value: flagValue,
			isSwitcheroo,
			tags,
			...otherFlagAttributes
		} = featureFlag;

		const { eventType, payload, excludeNotEnrolled } = {
			eventType: isSwitcheroo ? 'track' : 'operational',
			...options,
			excludeNotEnrolled: isSwitcheroo ?? options?.excludeNotEnrolled,
		};

		if (isNotEnrolled(currentPipeline) && excludeNotEnrolled) {
			return;
		}

		const eventPayload = typeof payload === 'function' ? payload(currentPipeline) : payload;

		const fireEvent =
			eventType === 'track' ? analytics.sendTrackEvent : analytics.sendOperationalEvent;

		// The format of this event should follow:
		// https://hello.atlassian.net/wiki/spaces/MEASURE/pages/361020395/3.+Exposure+tracking
		fireEvent({
			action: 'exposed',
			actionSubject: 'feature',
			...eventPayload,
			attributes: {
				flagKey,
				// value of the flag from launch darkly or statsig
				value: flagValue,

				...(!excludeNotEnrolled && {
					/**
					 * @private
					 * @deprecated cohort indicates the experience shown to the user, which
					 * could be different from the feature flag value.
					 * cohort should more often than not match value but
					 * if unenrolled this is often changed to not-enrolled. And if
					 * not-enrolled is excluded from feature exposed events, cohort would always
					 * be the same as value
					 */
					cohort,
					/**
					 * @private
					 * @deprecated ineligibilityReasons indicates why the cohort was
					 * determined to be ineligible for the experiment.
					 * Often used when cohort is not-enrolled. However if not-enrolled is
					 * excluded from feature exposed events, this would be undefined
					 */
					ineligibilityReasons,
				}),

				// this should allow for other values sent over by launch darkly
				// such as reason and ruleId to get attached to the event
				...otherFlagAttributes,
				// other custom attributes passed from function argument
				...(eventPayload && eventPayload.attributes),
			},
			tags: ['measurement', ...(tags || []), ...((eventPayload && eventPayload.tags) || [])],
		});
	}

	return {
		analytics,
		startRecording() {
			recording = true;
		},
		startSending(pipeline: Upstream) {
			recording = false;
			lastPipeline = pipeline;
			for (const { method, event } of recordedEvents) {
				sendOrRecordEvent(method, event);
			}
			recordedEvents.length = 0;
		},
		fireExperimentError,
		fireExposureEvent,
	};
};

export const useDelegateAsyncAnalytics = <
	Upstream extends ExperimentCore & Partial<ExperimentGetAnalyticsDefaults<Upstream>>,
>(
	analyticsImplementationPromise: Promise<AnalyticsImplementation>,
) =>
	function useAnalytics(
		pipeline: Upstream,
	): ExperimentAnalytics & ExperimentErrorHandler & ExperimentError {
		const implementationPromise = analyticsImplementationPromise;
		const [firstError, setFirstError] = React.useState(null);

		const awaitingImplementation: AnalyticsImplementation = React.useMemo(() => {
			const asyncSender =
				<M extends AnalyticsMethodName>(method: M) =>
				async (event: Parameters<AnalyticsImplementation[M]>[0]) => {
					try {
						return (await implementationPromise)[method](event as any);
					} catch (caughtError) {
						if (!firstError) {
							// @ts-ignore
							setFirstError(caughtError);
						}
					}
				};

			return {
				sendScreenEvent: asyncSender('sendScreenEvent'),
				sendUIEvent: asyncSender('sendUIEvent'),
				sendTrackEvent: asyncSender('sendTrackEvent'),
				sendOperationalEvent: asyncSender('sendOperationalEvent'),
			};
		}, [firstError, implementationPromise]);

		return {
			...useDelegateAnalytics<Upstream>(awaitingImplementation)(pipeline),
			error: markError(firstError, pipeline).error,
		};
	};

const toExperimentErrorAttributes = (error: ExperimentErrorType) => ({
	type: error.rawError && error.rawError.name,
	pluginIndex: error.pluginIndex,
	error: error.safeMessage,
	flagKey: error.flagKey,
});

export const useHandlerErrorAnalytics = <Upstream extends ExperimentAnalytics>() => {
	const [errorsFiredCount, setErrorsFiredCount] = React.useState(0);
	return (error: ExperimentErrorType, pipeline: Upstream) => {
		if (errorsFiredCount === 0) {
			pipeline.fireExperimentError(error);
			setErrorsFiredCount((errorsFiredCount) => errorsFiredCount + 1);
		}

		// eslint-disable-next-line no-console
		console.error(error);

		return {};
	};
};

type ExperimentGetAnalyticsDefaultsType<Upstream> = (
	pipeline: Upstream,
	method: AnalyticsMethodName,
) => Partial<AnalyticsEventType>;

type ExperimentGetAnalyticsDefaults<Upstream extends ExperimentCore> = {
	getAnalyticsDefaults: ExperimentGetAnalyticsDefaultsType<Upstream>;
};

export const usePluginAnalyticsDefaults = <Upstream extends ExperimentCore>(
	getAnalyticsDefaults: ExperimentGetAnalyticsDefaultsType<Upstream>,
) =>
	function useAnalyticsDefaults(pipeline: Upstream): ExperimentGetAnalyticsDefaults<Upstream> {
		return {
			getAnalyticsDefaults,
		};
	};
