import type { OperationVariables } from 'apollo-client';
import type { DocumentNode } from 'graphql';

import { query } from '@confluence/query-preloader-tools';

import { OBJECT_TYPES, PAGE_SIZE, WORKED_ON_EVENT_TYPES } from './constants/recentPagesQueryVars';
import {
	RecentlyDraftedQuery,
	RecentlyStarredQuery,
	RecentlyCreatedByMeQuery,
	RecentlyViewedQuery,
	RecentlyWorkedOnQuery,
} from './RecentPagesQueries.graphql';
import { ActivitiesObjectType } from './__types__/RecentlyWorkedOnQuery';

type PreloadRecentPagesOptions = {
	cloudId: string;
	recentTab?: number;
	isWhiteboardViewedEnabled?: boolean;
	isWhiteboardUpdatedEnabled?: boolean;
	isDatabaseEnabled?: boolean;
	isSmartLinkEmbedEnabled?: boolean;
	isSpaceAliasFFEnabled?: boolean;
};

export function preloadRecentPages({
	cloudId,
	recentTab = 0,
	isWhiteboardViewedEnabled = false,
	isWhiteboardUpdatedEnabled = false,
	isDatabaseEnabled = false,
	isSmartLinkEmbedEnabled = false,
	isSpaceAliasFFEnabled = false,
}: PreloadRecentPagesOptions) {
	const queries: Array<{
		query: DocumentNode;
		variables: OperationVariables;
	}> = getRecentPagesQueries(
		cloudId,
		isWhiteboardViewedEnabled,
		isWhiteboardUpdatedEnabled,
		isDatabaseEnabled,
		isSmartLinkEmbedEnabled,
		isSpaceAliasFFEnabled,
	);

	const queryParams =
		recentTab < 0 || recentTab >= queries.length ? queries[0] : queries[recentTab];

	return query(queryParams);
}

const getRecentPagesQueries = (
	cloudId: string,
	isWhiteboardViewedEnabled: boolean,
	isWhiteboardUpdatedEnabled: boolean,
	isDatabaseEnabled: boolean,
	isSmartLinkEmbedEnabled: boolean,
	isSpaceAliasFFEnabled: boolean,
) => [
	{
		query: RecentlyViewedQuery,
		variables: {
			cloudId,
			first: PAGE_SIZE,
			after: '',
			eventTypes: [],
			objectTypes: [
				...OBJECT_TYPES,
				...(isWhiteboardViewedEnabled ? [ActivitiesObjectType.WHITEBOARD] : []),
				...(isDatabaseEnabled ? [ActivitiesObjectType.DATABASE] : []),
				...(isSmartLinkEmbedEnabled ? [ActivitiesObjectType.EMBED] : []),
			],
		},
	},
	{
		query: RecentlyWorkedOnQuery,
		variables: {
			cloudId,
			first: PAGE_SIZE,
			after: '',
			eventTypes: WORKED_ON_EVENT_TYPES,
			objectTypes: [
				...OBJECT_TYPES,
				...(isWhiteboardUpdatedEnabled ? [ActivitiesObjectType.WHITEBOARD] : []),
				...(isDatabaseEnabled ? [ActivitiesObjectType.DATABASE] : []),
				...(isSmartLinkEmbedEnabled ? [ActivitiesObjectType.EMBED] : []),
			],
		},
	},
	{
		query: RecentlyCreatedByMeQuery,
		variables: {
			cql: 'type in (page, blogpost) and creator=currentUser() order by created desc',
			cqlcontext: `{"contentStatuses":["draft", "current"]}`,
			first: PAGE_SIZE,
			after: '',
			includeAlias: isSpaceAliasFFEnabled,
		},
	},
	{
		query: RecentlyStarredQuery,
		variables: {
			limit: PAGE_SIZE,
			start: 0,
		},
	},
	{
		query: RecentlyDraftedQuery,
		variables: {
			first: PAGE_SIZE,
			context: `{"contentStatuses":["draft"]}`,
			includeAlias: isSpaceAliasFFEnabled,
		},
	},
];
