import React from 'react';

import { Attribution, TransparentErrorBoundary } from '@confluence/error-boundary';
import { LoadableLazy } from '@confluence/loadable';
import { isTrialStandardDifferentiatorsEnrolled } from '@confluence/onboarding-helpers/entry-points/flags/experiment-edition-awareness-flags';

const StandardDifferentiatorsOnboardingLoadable = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-StandardDifferentiatorsOnboarding" */ '../src/StandardDifferentiatorsOnboarding'
			)
		).StandardDifferentiatorsOnboarding,
});

export const StandardDifferentiatorsOnboarding = () => {
	return isTrialStandardDifferentiatorsEnrolled() ? (
		<TransparentErrorBoundary attribution={Attribution.GROWTH_LUNA}>
			<StandardDifferentiatorsOnboardingLoadable />
		</TransparentErrorBoundary>
	) : null;
};
