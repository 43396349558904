import React from 'react';

import { Attribution, withErrorBoundary } from '@confluence/error-boundary';
import { LoadableLazy } from '@confluence/loadable';

const TrialFlagsLoadable = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-TrialComponentsFlags" */ './TrialFlags/TrialFlags'
			)
		).TrialFlags,
});

export const TrialFlags = withErrorBoundary({
	attribution: Attribution.CC_ONBOARDING,
})(() => <TrialFlagsLoadable />);

export const PremiumLozengeLoadable = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-PremiumLozenge" */ '../src/Lozenge/PremiumLozenge'
			)
		).PremiumLozenge,
});

export { default as PremiumLozengeImage } from './Lozenge/premiumLozenge.svg';

export { isTrialComponentsEnabledForEdition } from './isTrialComponentsEnabledForEdition';
