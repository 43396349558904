import type { FC } from 'react';
import React, { memo, useContext, useMemo, useRef } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl-next';

import type { NavigationTheme } from '@atlaskit/atlassian-navigation';
import { AtlassianNavigation } from '@atlaskit/atlassian-navigation';
import FeatureGates from '@atlaskit/feature-gate-js-client';

import { Attribution, ErrorBoundary, ErrorDisplay } from '@confluence/error-boundary';
import { EditionAwareness } from '@confluence/edition-awareness';
import { APP_NAV_GLOBAL_EXPERIENCE, ExperienceSuccess } from '@confluence/experience-tracker';
import { PageSegmentLoadEnd } from '@confluence/browser-metrics';
import type { useWebItemLocationWebItemType } from '@confluence/web-item-location';
import { useWebItemLocation, SYSTEM_HEADER_LEFT } from '@confluence/web-item-location';
import { isBranchDeployFn } from '@confluence/atlassian-internal-help-items';
import {
	ForgeKeyboardShortcut,
	FORGE_MODULE_GLOBAL_PAGE,
	LazyForgeUIExtensions,
} from '@confluence/forge-ui';
import { RoutesContext } from '@confluence/route-manager';
import { useBooleanFeatureFlag, useSessionData } from '@confluence/session-data';
import { SPAViewContext } from '@confluence/spa-view-context';
import {
	PerformanceEnd,
	PERFORMANCE_SUBJECT_navigation,
	PERFORMANCE_SUBJECT_navigationFMP,
} from '@confluence/performance';
import { OnboardingQuickstart } from '@confluence/onboarding-quickstart';
import { TrialFlags } from '@confluence/trial-components';
import { AppNavigationContext } from '@confluence/app-navigation-context';
import { ConditionalCreateSpaceDrawer } from '@confluence/create-space/entry-points/createSpace';
import { ConditionalCompanyHubBuilderDrawer } from '@confluence/company-hub/entry-points/CompanyHubBuilder';
import {
	ConditionalImportContentDrawer,
	ConditionalImportContentModal,
} from '@confluence/import-content/entry-points/importContent';
import { APP_NAVIGATION_METRIC } from '@confluence/browser-metrics/entry-points/app-navigation.metric';
import {
	ConversationAssistantNavBarButton,
	RovoEntitlementSetter,
} from '@confluence/conversation-assistant';
import { StorageEnforcement } from '@confluence/storage-enforcement/entry-points/StorageEnforcement';
import { isErrorMarkedAsHandled } from '@confluence/graphql';
import { StandardDifferentiatorsOnboarding } from '@confluence/experiment-standard-differentiators/entry-points/StandardDifferentiatorsOnboarding';
import { RovoVideoModal } from '@confluence/rovo-video-modal/entry-points/RovoVideoModal';
import { LoadableEndOfTrialModal } from '@confluence/end-of-trial-payment-modals/entry-points/LoadableEndOfTrialModal';

import { StandardDifferentiators } from './StandardDifferentiators';
import { usePrimaryItems } from './PrimaryItems';
import { useNavigationTheme } from './hooks/useNavigationTheme';
import { ConfluenceProductHome } from './ProductHome';
import { SPACE_DIRECTORY_KEY, PEOPLE_DIRECTORY_KEY } from './featureFlagKeys';
import {
	LazyAppSwitcher,
	LazyNotifications,
	LazySettings,
	LazyHelp,
	LazyProfile,
	LazySearch,
	LazyCreate,
	LazyNotes,
} from './appNavigationLazyItems';
import { NavSkeleton } from './NavSkeleton';
import { getForgeAppURL } from './PrimaryItems/Apps/AppForgeItem';
import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';

const i18n = defineMessages({
	navRegionLabel: {
		id: 'app-navigation.navigation.region.aria.label',
		defaultMessage: 'Global',
		description: 'A label for the top site navigation region',
	},
	moreLabel: {
		id: 'app-navigation.more.dropdown.label',
		defaultMessage: 'More',
		description:
			'Text on the dropdown that is shown when top nav options are collapsed into one menu',
	},
});

type AppNavigationComponentProps = {
	theme: NavigationTheme | undefined;
	homepageUri: string;
	homepageTitle: string;
	webItems: useWebItemLocationWebItemType[];
	isLimitedNav: boolean;
};

export type AppNavigationProps = {
	isLimitedNav?: boolean;
};

export type AppNavigationComponentType = FC<AppNavigationComponentProps>;
const EmptyItem = () => null;
const noop = () => {};

const GlobalPageKeyboardShortcuts = memo(() => {
	const { push } = useContext(RoutesContext);

	return (
		<LazyForgeUIExtensions
			moduleType={FORGE_MODULE_GLOBAL_PAGE}
			render={(forgeModules, loading, error) => {
				if (loading) return null;

				if (error) {
					if (!isErrorMarkedAsHandled(error)) {
						return <ErrorDisplay error={error} />;
					}
					return null;
				}

				return forgeModules.map((module) => (
					<ForgeKeyboardShortcut
						key={module.id}
						module={module}
						action={() => push(getForgeAppURL(module as any))}
					/>
				));
			}}
		/>
	);
});

const AppNavigationComponent: AppNavigationComponentType = ({
	theme,
	homepageUri,
	homepageTitle,
	webItems,
	isLimitedNav,
}) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const { formatMessage } = useIntl();
	const { primaryItems } = usePrimaryItems({
		customHomepage: { homepageUri, homepageTitle },
		webItems,
	});
	const {
		isLoggedIn,
		isAdminHubAIEnabled,
		isRovoEnabled: isRovoEnabledSessionData,
	} = useSessionData();
	const isUseRovoEntitlementFFEnabled = useBooleanFeatureFlag(
		`confluence.frontend.use-rovo-entitlement-check`,
	);
	const isRovoEnabled = isUseRovoEntitlementFFEnabled && isRovoEnabledSessionData && isLoggedIn;
	const isAiMateEnabled =
		useBooleanFeatureFlag(`confluence.frontend.ai.mate`) && isLoggedIn && isAdminHubAIEnabled;
	const isConversationAssistantEnabled = isRovoEnabled || isAiMateEnabled;

	const isNotesEnabled = useBooleanFeatureFlag(`confluence.frontend.notes`) && isLoggedIn;

	/* eslint-disable react/no-unstable-nested-components, react/function-component-definition */
	const RenderNotifications = useMemo(
		() => () => (
			<>
				{isConversationAssistantEnabled && <ConversationAssistantNavBarButton />}
				{FeatureGates.checkGate('rovo_use_entitlement_store') && (
					<RovoEntitlementSetter isRovoEnabled={isConversationAssistantEnabled} />
				)}
				{isNotesEnabled && <LazyNotes />}
				<LazyNotifications />
			</>
		),
		[isConversationAssistantEnabled, isNotesEnabled],
	);

	const RenderSearch = useMemo(
		() => () => (
			<>
				<EditionAwareness />
				<LazySearch theme={theme && theme.mode.search} />
			</>
		),
		[theme],
	);
	/* eslint-enable react/no-unstable-nested-components, react/function-component-definition */

	const RenderMoreLabel = useMemo(() => <FormattedMessage {...i18n.moreLabel} />, []);

	return (
		<ErrorBoundary
			attribution={Attribution.DISCO}
			attributes={{
				errorBoundaryId: 'AppNavigation-AppNavigationComponent',
			}}
		>
			<div data-vc="app-navigation-base" {...ssrPlaceholderIdProp}>
				<AtlassianNavigation
					moreLabel={RenderMoreLabel}
					renderAppSwitcher={LazyAppSwitcher}
					renderCreate={isLimitedNav ? EmptyItem : LazyCreate}
					renderHelp={LazyHelp}
					renderNotifications={isLimitedNav ? EmptyItem : RenderNotifications}
					renderProfile={LazyProfile}
					renderSettings={isLimitedNav ? EmptyItem : LazySettings}
					primaryItems={isLimitedNav ? [] : primaryItems}
					renderProductHome={ConfluenceProductHome}
					renderSearch={isLimitedNav ? EmptyItem : RenderSearch}
					theme={theme}
					label={formatMessage(i18n.navRegionLabel)}
				/>
			</div>
			<GlobalPageKeyboardShortcuts />
			<ExperienceSuccess name={APP_NAV_GLOBAL_EXPERIENCE} />
			<PageSegmentLoadEnd
				metric={APP_NAVIGATION_METRIC}
				customData={{
					isAnonymous: !isLoggedIn,
				}}
			/>
			<PerformanceEnd
				subject={PERFORMANCE_SUBJECT_navigation}
				subjectId="NavigationLoading"
				includeFeatureFlags
				details={{
					navVersion: '3',
				}}
			/>
			<PerformanceEnd
				subject={PERFORMANCE_SUBJECT_navigationFMP}
				subjectId="NavigationFMP"
				includeFeatureFlags
			/>
			<ConditionalCreateSpaceDrawer />
			<ConditionalCompanyHubBuilderDrawer />
			<ConditionalImportContentModal />
			<ConditionalImportContentDrawer />
		</ErrorBoundary>
	);
};

export const AppNavigation: FC<AppNavigationProps> = memo((props) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const { isLimitedNav = false } = props;
	const sessionData = useSessionData();
	const { featureFlags } = sessionData;
	const { loading: viewContextLoading, homepageUri, homepageTitle } = useContext(SPAViewContext);
	const { loading: themeLoading, theme } = useNavigationTheme();
	const resetStickySearchRef = useRef<() => void>(noop);
	const profileMenuButtonRef = useRef<HTMLButtonElement | null>(null);
	const primaryDropdownItemRef = useRef<HTMLButtonElement | null>(null);
	const createContentPopoverButtonRef = useRef<HTMLButtonElement | null>(null);
	const { loading: webItemsLoading, webItems } = useWebItemLocation({
		location: SYSTEM_HEADER_LEFT,
		allowedWebItems: [SPACE_DIRECTORY_KEY, PEOPLE_DIRECTORY_KEY],
	});

	const appNavigationContextValue = useMemo(
		() => ({
			isBranchDeploy: isBranchDeployFn(featureFlags),
			theme,
			resetStickySearchRef,
			profileMenuButtonRef,
			primaryDropdownItemRef,
			createContentPopoverButtonRef,
		}),
		[
			featureFlags,
			theme,
			resetStickySearchRef,
			profileMenuButtonRef,
			primaryDropdownItemRef,
			createContentPopoverButtonRef,
		],
	);

	return (
		<ErrorBoundary
			attribution={Attribution.DISCO}
			attributes={{
				errorBoundaryId: 'AppNavigation',
			}}
		>
			<AppNavigationContext.Provider value={appNavigationContextValue}>
				{viewContextLoading || themeLoading || webItemsLoading ? (
					<NavSkeleton />
				) : (
					<AppNavigationComponent
						theme={theme}
						homepageUri={homepageUri}
						homepageTitle={homepageTitle}
						webItems={webItems}
						isLimitedNav={isLimitedNav}
						{...ssrPlaceholderIdProp}
					/>
				)}
				<ErrorBoundary
					attribution={Attribution.CC_ONBOARDING}
					attributes={{
						errorBoundaryId: 'AppNavigation-Onboarding',
					}}
				>
					<OnboardingQuickstart />
					<StandardDifferentiatorsOnboarding />
					<RovoVideoModal />
				</ErrorBoundary>
				<StandardDifferentiators />
				<LoadableEndOfTrialModal>
					<TrialFlags />
				</LoadableEndOfTrialModal>
				<StorageEnforcement />
			</AppNavigationContext.Provider>
		</ErrorBoundary>
	);
});
