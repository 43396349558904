import { type ExperimentResolution } from '../portable/resolver';
import { type ExperimentCore } from '../core/types';
import { NOT_ENROLLED } from './cohorts';

export type NotEnrolledCohort = {
	notEnrolledCohort: string;
};

export const isNotEnrolled = <
	Upstream extends ExperimentCore & Partial<NotEnrolledCohort> & ExperimentResolution,
>(
	pipeline: Upstream,
) => {
	const notEnrolledName = pipeline.notEnrolledCohort || NOT_ENROLLED;
	return pipeline.cohort === notEnrolledName;
};

export const markNotEnrolled = <
	Upstream extends ExperimentCore & Partial<NotEnrolledCohort> & Partial<ExperimentResolution>,
>(
	ineligibilityReason: string,
	pipeline: Upstream,
): Upstream & ExperimentResolution => {
	return {
		...pipeline,
		cohort: pipeline.notEnrolledCohort || NOT_ENROLLED,
		ineligibilityReasons: [
			...(pipeline.ineligibilityReasons ?? []),
			ineligibilityReason || 'marked as not enrolled by experiment plugin',
		],
	};
};
