import { useState, useEffect } from 'react';

import { useSessionData } from '@confluence/session-data';

import {
	CONFLUENCE_SITE_WITH_INACTIVE_INVITED_USER_ELIGIBLE_FOR_REMINDER_TRAIT,
	CONFLUENCE_UI_CLICKED_BUTTON_REINVITE_RENOTIFY_LAST_TRAIT,
	CONFLUENCE_UI_CLICKED_BUTTON_DISMISS_RENOTIFY_FIRST_TRAIT,
	RENOTIFY_INVITEE_TARGETING_FF,
} from '../../constants/renotify-invitee/renotify-invitee-constants';
import { memoizedGetPersonalizationSiteUserTraits } from '../../utils/getPersonalizationSiteUserTraits';
import { useSitePersonalizationQuery } from '../useSitePersonalization/useSitePersonalizationQuery';
import { useIsExperimentFlagEnabled } from '../useIsExperimentFlagEnabled';

export type Trait = {
	name: string;
	value: string | boolean | number | null;
};

export const evaluateLastReinviteTrait = (trait?: Trait) => {
	// On initial state, the trait passed back would be undefined
	// thus, date.valueOf() would return `isNaN`.
	const date = new Date(trait?.value as string);
	if (!Number.isNaN(date.valueOf())) {
		// If the trait does return a date value, we want to check
		// whether the reinvite sent has been more than 2 days old
		// before we show the spotlight again
		date.setDate(date.getDate() + 2);

		return date < new Date();
	} else {
		// defaults to true for initial state
		return true;
	}
};

export const useRenotifyInviteeEligible = ({ skip = false }: { skip?: boolean }) => {
	const { cloudId, userId } = useSessionData();

	const [traitEligible, setTraitEligible] = useState(false);
	const [isDismissed, setIsDismissed] = useState(false);
	const [isRecentlyInvited, setIsRecentlyInvited] = useState(false);
	const [personalizationSiteUserLoading, setPersonalizationSiteUserLoading] = useState(false);

	const { attributes, loading: sitePersonalizationLoading } = useSitePersonalizationQuery({
		cloudId,
		skip: skip || (!cloudId && !userId),
	});

	// SITE TRAITs
	useEffect(() => {
		if (attributes) {
			// Eligibility is a Batch SITE TRAIT that returns the list of instances that are
			// eligible, defined as having pending invitations passed 7 days without acceptance
			const eligible =
				attributes.find(
					(att) =>
						att.name === CONFLUENCE_SITE_WITH_INACTIVE_INVITED_USER_ELIGIBLE_FOR_REMINDER_TRAIT,
				)?.value === 'true';

			// reinvited is a Real-time SITE Trait that returns the date when the admin last invited
			// else it returns undefined
			const reinvited = !evaluateLastReinviteTrait(
				attributes.find(
					(att) => att.name === CONFLUENCE_UI_CLICKED_BUTTON_REINVITE_RENOTIFY_LAST_TRAIT,
				),
			);

			setTraitEligible(eligible);
			setIsRecentlyInvited(reinvited);
		}
	}, [attributes]);

	// SITE_USER TRAIT
	useEffect(() => {
		const fetchData = async () => {
			if (traitEligible && !isRecentlyInvited && cloudId && userId) {
				setPersonalizationSiteUserLoading(true);
				try {
					const siteUserTraitsData = await memoizedGetPersonalizationSiteUserTraits(
						cloudId,
						userId,
					);
					// dismissed is a Real-time SITE_USER Trait that returns true only when admin has dismissed
					// else it returns undefined
					const dismissed =
						siteUserTraitsData?.attributes.find(
							(att: any) => att.name === CONFLUENCE_UI_CLICKED_BUTTON_DISMISS_RENOTIFY_FIRST_TRAIT,
						)?.value === 'true';
					setIsDismissed(dismissed);
				} catch (error) {
					// should we still show the experience if this query fails
					setIsDismissed(false);
				} finally {
					setPersonalizationSiteUserLoading(false);
				}
			}
		};
		if (!skip) {
			void fetchData();
		}
	}, [traitEligible, cloudId, userId, skip, isRecentlyInvited]);

	const isRenotifyExperimentTargetingOn = useIsExperimentFlagEnabled({
		flagName: RENOTIFY_INVITEE_TARGETING_FF,
		localeRestriction: 'english',
		skip: skip || !traitEligible || isDismissed || isRecentlyInvited,
	});

	if (skip) {
		return undefined;
	}

	return {
		isLoading: sitePersonalizationLoading || personalizationSiteUserLoading,
		isEnrolled: isRenotifyExperimentTargetingOn,
	};
};
