import { createActionsHook, createStateHook, createStore } from 'react-sweet-state';

const Store = createStore({
	initialState: {
		isGlobalCreateSpotlightPulseActive: false,
	},
	actions: {
		toggleGlobalCreateSpotlightPulse:
			(isActive: boolean) =>
			({ setState }) => {
				setState({ isGlobalCreateSpotlightPulseActive: isActive });
			},
	},
	name: 'SmartLinkEmbedOnboardingStore',
});

export const useGlobalCreateSpotlightPulseState = createStateHook(Store, {
	selector: (state) => state.isGlobalCreateSpotlightPulseActive,
});

export const useSmartLinkEmbedOnboardingActions = createActionsHook(Store);
