import { createHook, createStore } from 'react-sweet-state';

type State = {
	showDrawer: boolean;
};

const initialState: State = {
	showDrawer: false,
};

export const actions = {
	openDrawer:
		() =>
		({ setState }) => {
			setState({
				showDrawer: true,
			});
		},
	closeDrawer:
		() =>
		({ setState }) => {
			setState({
				showDrawer: false,
			});
		},
};

export const Store = createStore({
	initialState,
	actions,
	name: 'companyHubBuilderStore',
});

export const useCompanyHubBuilderStore = createHook(Store);
